import Card from '~components/PageBlocks/Card';
import { Flex, Heading } from '@rebass/emotion';

export default function RelatedContent({ pages }) {
  if (!pages?.length) {
    return null;
  }

  return (
    <Flex
      width="100%"
      flexDirection="column"
      mt="20px"
      px={['20px', '20px', '20px', '20px', 0]}
    >
      <Heading
        as="h2"
        fontSize="28px"
        color="#1a3092"
        fontWeight="bold"
        style={{ borderBottom: '1px solid #172b4d' }}
        pb="40px"
      >
        Related content
      </Heading>
      {pages.map(page => {
        return <Card type={2} data={{ page }} key={page._id} />;
      })}
    </Flex>
  );
}
