import React from 'react';
import { graphql } from 'gatsby';
import { Text, Heading } from '@rebass/emotion';
import CmsPage from '../components/Elements/CmsPage';
import { PageProvider } from '../context/PageProvider';
import { MediaResourceProvider } from '../context/MediaResourceProvider';
import { Header, Footer } from '../components/PageBlocks';
import RichTextcontent from '~components/PageBlocks/RichTextContent';
import Breadcrumbs from '~components/PageBlocks/Breadcrumbs';
import RelatedContent from '~components/PageBlocks/RelatedContent';
import FilterButtons from '~components/PageBlocks/FilterButtons';
import Link from '~components/PageBlocks/Link';

const Article = ({ pageContext, data }) => {
  const { menu, article } = pageContext;

  pageContext.article = data.sanityContentPage;

  function pageWidth() {
    if (pageContext.pageWidth == null || pageContext.pageWidth == 'standard') {
      return 'max-w-screen-md flex-col';
    }

    if (pageContext.pageWidth == 'fullWidth') {
      return 'max-w-screen-xl flex-col';
    }
    if (pageContext.pageWidth == 'sidebar') {
      return 'flex-col md:flex-row max-w-screen-xl items-stretch';
    }
  }
  return (
    <CmsPage pageContext={pageContext}>
      <PageProvider page={pageContext}>
        <MediaResourceProvider>
          <div className="flex flex-col min-h-screen p-0 max-w-full">
            <Header data={pageContext.header} />
            {/* <ArticleSubHeader data={pageContext} /> */}
            <Breadcrumbs data={pageContext} />
            <div
              className={`flex w-full mx-auto justify-start items-start py-[5vh] px-5 sm:px-10 ${pageWidth()}`}
            >
              {pageContext.pageWidth == 'sidebar' ? (
                <>
                  <div
                    id="sidebar"
                    className="w-full md:w-1/4 md:pr-10 md:border-r md:border-gray-200"
                  >
                    <div className="sticky top-36">
                      <Heading
                        as="h1"
                        fontSize="44px"
                        fontWeight="bold"
                        color={'blueBlue160'}
                        textAlign="left"
                      >
                        {pageContext.article.title}
                      </Heading>
                      <Text
                        fontSize="20px"
                        lineHeight="28px"
                        color={'neutralNeutral170'}
                        textAlign="left"
                        mt="30px"
                        mb="20px"
                      >
                        {pageContext.article.description}
                      </Text>
                      <FilterButtons />
                    </div>
                  </div>
                  <div className="w-full md:w-3/4 md:pl-10">
                    <RichTextcontent
                      width={pageContext.pageWidth}
                      data={{ blockContent: pageContext.article.body }}
                    />

                    <RelatedContent
                      pages={pageContext.article.relatedContent}
                    />
                  </div>
                </>
              ) : (
                <>
                  <Heading
                    as="h1"
                    fontSize="44px"
                    fontWeight="bold"
                    color={'blueBlue160'}
                    textAlign="left"
                  >
                    {pageContext.article.title}
                  </Heading>
                  <Text
                    fontSize="20px"
                    lineHeight="28px"
                    color={'neutralNeutral170'}
                    textAlign="left"
                    mt="30px"
                    mb="20px"
                  >
                    {pageContext.article.description}
                  </Text>
                  <RichTextcontent
                    width={pageContext.pageWidth}
                    data={{ blockContent: pageContext.article.body }}
                  />
                  <RelatedContent pages={pageContext.article.relatedContent} />
                </>
              )}
            </div>

            <Footer data={pageContext.footer} />
          </div>
        </MediaResourceProvider>
      </PageProvider>
    </CmsPage>
  );
};

export default Article;

function ArticleSubHeader({ data }) {
  const title = data.article.topic.title;

  return (
    <div className="w-full mx-auto max-w-screen-xl px-5 md:px-10 pt-5">
      <Heading as="h1" fontSize="44px" fontWeight="bold" color="black">
        Autism in {data?.article?.topic?.section}
      </Heading>
      <div className="flex flex-row space-x-4">
        {data?.menu?.items.map(item => {
          if (item.page && item.page._type === 'staticPage') {
            return null;
          }
          return (
            <Link
              data={item}
              className={`hover:underline ${
                title === item.page.title
                  ? 'font-bold underline'
                  : 'no-underline'
              }`}
            >
              {item.page.title}
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export const query = graphql`
  query ExploreArticleQuery($articleId: String) {
    sanityContentPage(_id: { eq: $articleId }) {
      _id
      _type
      name
      title
      description
      slug {
        _key
        _type
        current
      }
      topic {
        _id
        _type
        title
        description
        section
        slug {
          _key
          _type
          current
        }
      }
      body: _rawBody(resolveReferences: { maxDepth: 20 })
      image {
        crop {
          top
          left
          bottom
          right
        }
        hotspot {
          height
          width
          x
          y
        }
        asset {
          url
          path
          title
          description
          altText
        }
      }
      relatedContent {
        ... on SanityContentPage {
          _id
          _type
          name
          title
          description
          slug {
            current
          }
          topic {
            _id
            section
            slug {
              current
            }
          }
          image {
            crop {
              top
              left
              bottom
              right
            }
            hotspot {
              height
              width
              x
              y
            }
            asset {
              url
              path
              title
              description
              altText
            }
          }
        }
      }
    }
  }
`;
