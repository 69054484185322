import { useMediaResourceContext } from '../../context/MediaResourceProvider';
import WatchIcon from '~icons/watch';
import ReadIcon from '~icons/read';
import ListenIcon from '~icons/listen';

export default function FilterButtons() {
  let {
    activeFilter,
    setActiveFilter,
    mediaResourcesData,
    setMediaResourcesItems,
  } = useMediaResourceContext();

  const menuItems = [...new Set(mediaResourcesData?.map(item => item.type))];

  const filterItems = type => {
    const newItem = mediaResourcesData?.filter(item => {
      return item.type === type;
    });
    setActiveFilter(newItem[0]?.type);
    setMediaResourcesItems(newItem);
    window.scrollTo(0, 0);
  };

  function iconSwitch(type, activeFilter) {
    switch (type) {
      case 'watch':
        return <WatchIcon stroke={activeFilter == type ? 2 : 1} />;
      case 'read':
        return <ReadIcon stroke={activeFilter == type ? 2 : 1} />;
      case 'listen':
        return <ListenIcon stroke={activeFilter == type ? 2 : 1} />;
    }
  }

  return (
    <div className="rounded-lg p-5 mb-10 bg-background-blue space-y-5">
      <h4 className="border-b font-medium uppercase text-sm pb-2">
        Filter by Media Type
      </h4>
      <div className="flex space-x-4 md:space-x-0 md:space-y-3 md:flex-col items-start text-left md:border-b md:pb-5">
        <button
          className={`capitalize ${
            activeFilter == null ? 'font-bold' : 'font-normal'
          }`}
          onClick={() => [
            setMediaResourcesItems(mediaResourcesData),
            setActiveFilter(null),
            setTimeout(window.scrollTo(0, 0)),
          ]}
        >
          All
        </button>
        {menuItems.map((item, id) => {
          return (
            <button
              className={`capitalize ${
                activeFilter == item ? 'font-bold' : 'font-normal'
              }`}
              onClick={() => filterItems(item)}
              key={id}
            >
              <div className="space-x-2 flex flex-row">
                {iconSwitch(item, activeFilter)}{' '}
                <span className="capitalize">{item}</span>
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
}
